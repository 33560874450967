<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="closeDialog"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="true"
                :formItems="popForms"/>
            </a-modal>
        <m-form
            :formItems="formItems"
            @loadData="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
            :totals="total"
            :currentPage="currentPage"
            @pageChange="loadData($event)"
            @modifyStatus="modifyStatus($event)"
            @detail="detail($event)"
            @stock="stock($event)"></m-table>
        <agentStock v-model:show="stockInfo.show" :data="stockInfo.data"/>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { isValid,  agentList, agentDetail } from '../../utils/api'
import { assginDataToArray, geneIndex } from '@/utils/util'
import agentStock from './components/stock.vue'
export default {
    name: '',
    components:{ agentStock },
    setup() {
         const state= reactive({
             title: '详情',
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '详情', eventName: 'detail'},
                 {text: '库存', eventName: 'stock'},
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '真实姓名', dataIndex: 'realName', key: 'realName'},
                 {title: '手机号码', dataIndex: 'phone', key: 'phone'},
                 {title: '昵称', dataIndex: 'nickName', key: 'nickName'},
                 {title: 'agentId', dataIndex: 'agentId', key: 'agentId'},
                 {title: '模式', dataIndex: 'saleModelStr', key: 'saleModelStr'},
                 {title: '角色', dataIndex: 'agentLevelStr', key: 'agentLevelStr'},
                 {title: '所属应用', dataIndex: 'appName', key: 'appName'},
                 {title: '加入时间', dataIndex: 'createTime', key: 'createTime'},
                 {title: '团队人数', dataIndex: 'teamAgentCount', key: 'teamAgentCount'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                 {type: 'input', label: '代理姓名', value: undefined},
                 {type: 'button', eventName: 'loadData', label: '搜索'},
             ],
             checkForms: [
                 {label: '代理状态', type: 'selector', value: '', key: 'value', prop: 'status', options: [{labelName: '待处理', value: 0}, {labelName: '通过', value: 1}, {labelName: '驳回', value: 2}]},
                 {label: '留言', type: 'input', value: '', prop: 'remark'}
             ],
             popForms: [
                 {label: 'ID', prop: 'agentId', value: '', type: 'input'},
                 {label: '真实姓名', prop: 'realName', value: '', type: 'input'},
                 {label: '手机号', prop: 'phone', value: '', type: 'input'},
                 {label: '昵称', prop: 'nickName', value: '', type: 'input'},
                 {label: '加入时间', prop: 'joinTeamTime', value: '', type: 'input'},
                 {label: '团队人数', prop: 'teamAgentCount', value: '', type: 'input'},
            ],
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             selectedIDs: [],
             loading: false,
             status: 0,
             detail: {},
             stockInfo:{
                show: false,
                data:null
             }
        })
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    realName: state.formItems[0].value
                }
                const result = await agentList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = geneIndex(result.data.list, state.pageSize, state.currentPage)
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        async function detail(event) {
            try {
                const result = await agentDetail(event.agentId)
                if (isValid(result)) {
                    state.popForms = assginDataToArray(result.data, state.popForms)
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }
       
        function closeDialog() {
            loadData()
            state.showDialog = false
        }

        // 点击库存
        function stock(e){
            state.stockInfo.show = true
            state.stockInfo.data = e
        }
        
        return {
            ...toRefs(state),
            detail,
            loadData,
            closeDialog,
            stock
        }
    },
}
</script>
<style lang='scss' scoped>
</style>